// globals

const e_root = document.querySelector(':root');

window.addEventListener('load', () => {
  e_root.style.setProperty('--firstViewHeight', `${window.innerHeight}px`);
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})

window.addEventListener('resize', () => {
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})