// globals

const e_root = document.querySelector(':root');

window.addEventListener('load', () => {
  e_root.style.setProperty('--firstViewHeight', `${window.innerHeight}px`);
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})

window.addEventListener('resize', () => {
  e_root.style.setProperty('--vw', `${window.innerWidth}px`);
})
//noScroll
class AccordionEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    $(function(){
      //クリックで動く
      $('.js-acco-open').click(function(){
        $(this).toggleClass('is-active');
        $(this).find('.js-acco-description').slideToggle();
      });
    });

  }
}

var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
// 参考サイト
// https://hacknote.jp/archives/15289/

var cookie = {
	/**
	 * 取得
	 * @param {string} cookieName 取得するCookieの名前
	 */
	get : function(cookieName) {
		if (!navigator.cookieEnabled) return false;

		var getCookieAllData  = document.cookie,
		    getCookieAllDatas = getCookieAllData.split(';'),
		    getCookieDatas    = {},
		    cookieKeyValue    = [],
		    cookieKey         = '',
		    cookieValue       = '';

		var hashLength = function(arr) {
			var result = 0, key = false;

			if (typeof arr !== 'object') return false;

			for (key in arr) result++;

			return result;
		};

		for (var i = 0, len = getCookieAllDatas.length; i < len; i++) {
			if (getCookieAllDatas[i] && getCookieAllDatas[i] !== '') {
				cookieKeyValue = getCookieAllDatas[i].split('=');
				cookieKey      = cookieKeyValue[0].replace(/^\s+/, '');
				cookieValue    = '';

				if (cookieKeyValue[1]) {
					cookieValue = decodeURIComponent(cookieKeyValue[1]);
				}

				getCookieDatas[cookieKey] = cookieValue;
			}
		}

		if (hashLength(getCookieDatas) > 0) {
			if (cookieName && cookieName in getCookieDatas) {
				return getCookieDatas[cookieName];
			} else {
				return getCookieDatas;
			}
		} else {
			return '';
		}
	},

	/**
	 * 設定
	 * @param {string} cookieName 設定するCookieの名前
	 * @param {string} value 設定する値
	 * @param {string} domain 有効ドメイン
	 * @param {string} path 有効パス
	 * @param {string} expires 有効期限
	 * @param {boolean} secure セキュアとするか
	 * @return {boolean} 設定に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	set : function(cookieName, value, domain, path, expires, secure) {
		if (!navigator.cookieEnabled || !cookieName || !value) return false;

		var date, dt, ts, ts_after;

		var setCookieData = cookieName + '=' + encodeURIComponent(value) + ';';

		if (domain) setCookieData += 'domain=' + domain + ';';

		if (path) setCookieData += 'path=' + path + ';';

		if (expires) {
      dt = new Date();
      ts = dt.getTime();

      // 1時間後
      ts_after = ts + (1000 * 60 * 60 * expires);

      date = new Date(ts_after);
			setCookieData += 'expires=' + date.toGMTString() + ';';
		}

		if (secure && secure === true && location.protocol.match(/https/)) {
			setCookieData += 'secure';
		}

		document.cookie = setCookieData;

		return true;
	},

	/**
	 * 削除
	 * @param {string} cookieName 削除するCookieの名前
	 * @return {boolean} 削除に成功した場合はtrue、失敗した場合はfalseを返す
	 */
	del : function(cookieName) {
		if (!navigator.cookieEnabled || !cookieName) return false;

		var getCookieDatas = this.get();

		if (getCookieDatas[cookieName]) {
			var setCookieData = cookieName + '=;',
			    date          = new Date(0);

			setCookieData += 'expires=' + date.toGMTString() + '';

			document.cookie = setCookieData;

			return true;
		}

		return false;
	}
};
class HamburgerEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

  const humEvent = () => {
    const hum = document.querySelector(".nav-hum")
    const humContainer = document.querySelector(".hum-container")
    const humSns = document.querySelector(".js-sns")
    const body = document.querySelector("body")
    const headerLogo =document.querySelector(".l-header__logo")
    const humBar = document.querySelector(".nav-hum")

    hum.addEventListener("click", () => {
      hum.classList.toggle("is-active")
      humContainer.classList.toggle("is-active")
      humSns.classList.toggle("is-hidden")
      body.classList.toggle("is-open")
      headerLogo.classList.toggle("is-active")
    })
  }

  humEvent();

  if( window.matchMedia("screen and (min-width:768px)").matches ) {
  const humHoverEvent = () => {
    const hoverImg = document.querySelectorAll(".js-hum-img")

    const hoverBase = document.querySelector("#base")
    const hoverCommitment = document.querySelector("#commitment")
    const hoverMenu = document.querySelector("#menu")
    const hoverNews = document.querySelector("#news")
    const hoverStore = document.querySelector("#store")

    hoverImg.forEach(ele => {

      const hoverData = ele.dataset.img;

      ele.addEventListener("mouseover", function() {
        hoverBase.classList.remove("is-active")

        switch (hoverData){
          case 'commitment':
            hoverCommitment.classList.add("is-active")
            break;
          case 'menu':
            hoverMenu.classList.add("is-active")
            break;
          case 'news':
            hoverNews.classList.add("is-active")
            break;
          case 'store':
            hoverStore.classList.add("is-active")
            break;
          default:
            // console.log('ホバーしていないです。');
        }
      })

      ele.addEventListener("mouseleave", function() {
        hoverBase.classList.add("is-active")

        switch (hoverData){
          case 'commitment':
            hoverCommitment.classList.remove("is-active")
            break;
          case 'menu':
            hoverMenu.classList.remove("is-active")
            break;
          case 'news':
            hoverNews.classList.remove("is-active")
            break;
          case 'store':
            hoverStore.classList.remove("is-active")
            break;
          default:
            // console.log('ホバーしていないです。');
        }
      })
    })
  }
  humHoverEvent();
  }





  }
}



//noScroll
class HeaderEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let beforePos = 0;

    function ScrollAnime() {
      let elemTop =100;//.p-top__mvの位置まできたら
      let scroll = $(window).scrollTop();
        //ヘッダーの出し入れをする
        if(scroll == beforePos) {
        //IE11対策で処理を入れない
        }else if(elemTop > scroll || 0 > scroll - beforePos){
        //ヘッダーが上から出現する
        $('.js-header').removeClass('UpMove');	//.js-headerにUpMoveというクラス名を除き
        $('.js-header').addClass('DownMove');//.js-headerにDownMoveのクラス名を追加
        }else {
        //ヘッダーが上に消える
            $('.js-header').removeClass('DownMove');//.js-headerにDownMoveというクラス名を除き
        $('.js-header').addClass('UpMove');//.js-headerにUpMoveのクラス名を追加
        }

        beforePos = scroll;//現在のスクロール値を比較用のbeforePosに格納
    }

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
  ScrollAnime();//スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
});


  }
}

//noScroll
class HeadingEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

      // eachTextAnimeにappeartextというクラス名を付ける定義
      function EachTextAnimeControl() {

        // spanタグを追加する
        var element = $(".eachTextAnime");
        element.each(function () {
          var text = $(this).text();
          var textbox = "";
          text.split('').forEach(function (t, i) {
            if (t !== " ") {
              if (i < 10) {
                textbox += '<span style="animation-delay:.' + i + 's;">' + t + '</span>';
              } else {
                var n = i / 10;
                textbox += '<span style="animation-delay:' + n + 's;">' + t + '</span>';
              }

            } else {
              textbox += t;
            }
          });
          $(this).html(textbox);
        });


        $('.eachTextAnime').each(function () {
          $(this).addClass("appeartext");
        });
      }
      // EachTextAnimeControl();/* アニメーション用の関数を呼ぶ*/

      // ローディング
      function loading() {
        // ロゴの表示
        const mvLogo = '.js-mv-logo';
        // $(mvLogo).fadeOut('500')
        gsap.to(mvLogo,{
          opacity: 1,
          filter: "blur(0px)",
          duration: 1
        })
      }
      loading();
      // ローディング後
      function afterLoading() {
        // ロゴの非表示
        const mvLogo = '.js-mv-logo';
        // $(mvLogo).fadeOut('500')
        gsap.to(mvLogo,{
          opacity: 0,
          duration: 1.5
        })
        // 背景画像の表示
        const mvBg = '.js-mv-bg';
        gsap.to(mvBg,{
          opacity: 1,
          duration: 1.5
        })
        // キャッチコピーの表示
        const mvText = '.js-mv-text';
        gsap.to(mvText,{
          opacity: 1,
          delay: 1,
          duration: 1.5
        })
        // キャッチコピー背景の表示
        const mvTextBg = '.js-mv-text-bg';
        gsap.to(mvTextBg,{
          opacity: 1,
          delay: 2,
          duration: 1.5
        })
        // キャッチコピーの入れ替え
        const mvTextBefore = '.js-mv-text-before';
        const mvTextAfter = '.js-mv-text-after';
        gsap.to(mvTextAfter,{
          opacity: 1,
          delay: 3
        })
      }
      setTimeout(afterLoading, 3000);
  }
}

//noScroll
class ImgParallax {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){
    this.para_ele = document.querySelectorAll("." + this.el);
    this.para_ele.forEach((ele, index)=> {
      if(ele.classList.contains('js-parallax--reverse')) {
        this.translateY = '20px';
      } else {
        this.translateY = '-20px';
      }
      ele.classList.add(this.el + index);
      gsap.to('.' + this.el + index + ' img', {
        translateY: this.translateY,
        scrollTrigger: {
          trigger: '.' + this.el + index, // 要素がビューポートに入ったときにアニメーション開始
          start: 'top bottom',
          scrub: 3,
        }
      })
    })


    gsap.utils.toArray('.js-parallax-fideUp').forEach(wrap => {
      const y = wrap.getAttribute('data-y') || -100;

      gsap.to(wrap, {
        y: y,
        scrollTrigger: {
          trigger: wrap,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 1,

        }
      })
    });


  }
}

class InviewEvent {
  constructor(el, opt){
    this.el = el;
    this.all = this.el + '-all';
    this.eachUp = this.el + '-each';
    this.eachItem = this.el + '-eachItem';
    this.eachItemFast = this.el + '-eachItem-fast';
    this.eachItemDelay = this.el + '-eachItem-delay';
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    let options = {
      threshold: [0, 0.05, 0.1, 1]
    }
    let optionsAll = {
      threshold: [0, 0.9, 1]
    }
    const observerEnter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.05){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          if(item.classList.contains('js-inview-lottie')){
            this.inviewLottie(item);
          }
          observerEnter.unobserve(item);
        }
      });
    }, options);
    const observerAll = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const item = entry.target;
        if(entry.intersectionRatio >= 0.9){
          item.classList.add('is-inview');
          if(item.classList.contains('js-inview-each')){
            this.inviewEachUp(item);
          }
          observerAll.unobserve(item);
        } else {
          item.classList.remove('is-inview');
        }
      });
    }, optionsAll);
    const inviewItem = document.querySelectorAll(this.el);
    const inviewAllItem = document.querySelectorAll(this.all);
    inviewItem.forEach((e)=>{
      observerEnter.observe(e);
    });
    inviewAllItem.forEach((e)=>{
      observerAll.observe(e);
    });
  }
  inviewEachUp(item){
    $(item).find(this.eachItem).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.2 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemFast).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.1 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
    $(item).find(this.eachItemDelay).each((i,el)=>{
      gsap.to($(el), {
        // duration: 0.8,
        // alpha: 1,
        delay: (0.5 * i),
        // y: 0,
        // x: 0,
        onComplete: ()=>{
          $(el).addClass('is-inview');
        }
      });
    });
  }
  inviewLottie(item){
    if($(item).hasClass('js-lottie-flow')) {
      lottie.flow();
    }
    if($(item).hasClass('js-lottie-no01')) {
      lottie.no01();
    }
    if($(item).hasClass('js-lottie-no02')) {
      lottie.no02();
    }
    if($(item).hasClass('js-lottie-no03')) {
      lottie.no03();
    }
    if($(item).hasClass('js-lottie-no04')) {
      lottie.no04();
    }
    if($(item).hasClass('js-lottie-no05')) {
      lottie.no05();
    }
    if($(item).hasClass('js-lottie-no06')) {
      lottie.no06();
    }
    if($(item).hasClass('js-lottie-no07')) {
      lottie.no07();
    }
  }
}



(() => {

})();

//DOMContentLoaded
$(() => {
});

//images resources loaded
$(window).on('load', () => {

  $(window).trigger('loading');
  let headingEvent = new HeadingEvent('js-headimg');
});

//after loading animation
$(window).on('loading', () => {
  let imgParallax = new ImgParallax('js-parallax');
  let inviewEvent = new InviewEvent('.js-inview');
  let headerEvent = new HeaderEvent('js-header');
  let hamburgerEvent = new HamburgerEvent('js-hum');
  let accordionEvent = new AccordionEvent('js-acco');
});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });