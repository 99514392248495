//noScroll
class HeaderEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

    let beforePos = 0;

    function ScrollAnime() {
      let elemTop =100;//.p-top__mvの位置まできたら
      let scroll = $(window).scrollTop();
        //ヘッダーの出し入れをする
        if(scroll == beforePos) {
        //IE11対策で処理を入れない
        }else if(elemTop > scroll || 0 > scroll - beforePos){
        //ヘッダーが上から出現する
        $('.js-header').removeClass('UpMove');	//.js-headerにUpMoveというクラス名を除き
        $('.js-header').addClass('DownMove');//.js-headerにDownMoveのクラス名を追加
        }else {
        //ヘッダーが上に消える
            $('.js-header').removeClass('DownMove');//.js-headerにDownMoveというクラス名を除き
        $('.js-header').addClass('UpMove');//.js-headerにUpMoveのクラス名を追加
        }

        beforePos = scroll;//現在のスクロール値を比較用のbeforePosに格納
    }

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
  ScrollAnime();//スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
});


  }
}
