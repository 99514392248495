class HamburgerEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

  const humEvent = () => {
    const hum = document.querySelector(".nav-hum")
    const humContainer = document.querySelector(".hum-container")
    const humSns = document.querySelector(".js-sns")
    const body = document.querySelector("body")
    const headerLogo =document.querySelector(".l-header__logo")
    const humBar = document.querySelector(".nav-hum")

    hum.addEventListener("click", () => {
      hum.classList.toggle("is-active")
      humContainer.classList.toggle("is-active")
      humSns.classList.toggle("is-hidden")
      body.classList.toggle("is-open")
      headerLogo.classList.toggle("is-active")
    })
  }

  humEvent();

  if( window.matchMedia("screen and (min-width:768px)").matches ) {
  const humHoverEvent = () => {
    const hoverImg = document.querySelectorAll(".js-hum-img")

    const hoverBase = document.querySelector("#base")
    const hoverCommitment = document.querySelector("#commitment")
    const hoverMenu = document.querySelector("#menu")
    const hoverNews = document.querySelector("#news")
    const hoverStore = document.querySelector("#store")

    hoverImg.forEach(ele => {

      const hoverData = ele.dataset.img;

      ele.addEventListener("mouseover", function() {
        hoverBase.classList.remove("is-active")

        switch (hoverData){
          case 'commitment':
            hoverCommitment.classList.add("is-active")
            break;
          case 'menu':
            hoverMenu.classList.add("is-active")
            break;
          case 'news':
            hoverNews.classList.add("is-active")
            break;
          case 'store':
            hoverStore.classList.add("is-active")
            break;
          default:
            // console.log('ホバーしていないです。');
        }
      })

      ele.addEventListener("mouseleave", function() {
        hoverBase.classList.add("is-active")

        switch (hoverData){
          case 'commitment':
            hoverCommitment.classList.remove("is-active")
            break;
          case 'menu':
            hoverMenu.classList.remove("is-active")
            break;
          case 'news':
            hoverNews.classList.remove("is-active")
            break;
          case 'store':
            hoverStore.classList.remove("is-active")
            break;
          default:
            // console.log('ホバーしていないです。');
        }
      })
    })
  }
  humHoverEvent();
  }





  }
}


