//noScroll
class HeadingEvent {
  constructor(el,opt){
    this.el = el;
    if($("." + this.el).length > 0){
      this.event();
    }
  }
  event(){

      // eachTextAnimeにappeartextというクラス名を付ける定義
      function EachTextAnimeControl() {

        // spanタグを追加する
        var element = $(".eachTextAnime");
        element.each(function () {
          var text = $(this).text();
          var textbox = "";
          text.split('').forEach(function (t, i) {
            if (t !== " ") {
              if (i < 10) {
                textbox += '<span style="animation-delay:.' + i + 's;">' + t + '</span>';
              } else {
                var n = i / 10;
                textbox += '<span style="animation-delay:' + n + 's;">' + t + '</span>';
              }

            } else {
              textbox += t;
            }
          });
          $(this).html(textbox);
        });


        $('.eachTextAnime').each(function () {
          $(this).addClass("appeartext");
        });
      }
      // EachTextAnimeControl();/* アニメーション用の関数を呼ぶ*/

      // ローディング
      function loading() {
        // ロゴの表示
        const mvLogo = '.js-mv-logo';
        // $(mvLogo).fadeOut('500')
        gsap.to(mvLogo,{
          opacity: 1,
          filter: "blur(0px)",
          duration: 1
        })
      }
      loading();
      // ローディング後
      function afterLoading() {
        // ロゴの非表示
        const mvLogo = '.js-mv-logo';
        // $(mvLogo).fadeOut('500')
        gsap.to(mvLogo,{
          opacity: 0,
          duration: 1.5
        })
        // 背景画像の表示
        const mvBg = '.js-mv-bg';
        gsap.to(mvBg,{
          opacity: 1,
          duration: 1.5
        })
        // キャッチコピーの表示
        const mvText = '.js-mv-text';
        gsap.to(mvText,{
          opacity: 1,
          delay: 1,
          duration: 1.5
        })
        // キャッチコピー背景の表示
        const mvTextBg = '.js-mv-text-bg';
        gsap.to(mvTextBg,{
          opacity: 1,
          delay: 2,
          duration: 1.5
        })
        // キャッチコピーの入れ替え
        const mvTextBefore = '.js-mv-text-before';
        const mvTextAfter = '.js-mv-text-after';
        gsap.to(mvTextAfter,{
          opacity: 1,
          delay: 3
        })
      }
      setTimeout(afterLoading, 3000);
  }
}
